.App {
  text-align: center;
}
.topcontainer {
  text-align: right;
  margin-bottom: 10px;
}

.navbarlink {
  margin-right: 10px;
}

.container-fluid {
  margin-top: 10px;
}
.navMenu button {
  margin: 5px;
}
.calendar-day-holiday a, .calendar-day-holiday a:hover {
  color: red;
}

.calendar-day a, .calendar-day a:hover {
  color: black;
}

.calendar-day-today a, .calendar-day-today a:hover {
  border-radius: 250%;
  color: white;
  background: red;
}

td a{
  display: block;
  text-decoration: none;
}

.nameLink, .fromNameLink {
  text-decoration: none;
  color: #0a53be;
}

.mt-4 {
  max-width: 40%;
  margin: 0 auto;
  text-align: center;
}

.months {
  display: inline-block;
  vertical-align: top;
  margin: 20px;
}

.info {
  margin-left: 5%;
  margin-right: 5%;
}

.name-day-table {
  margin: 20px;
}

.nameDescription {
  margin-left: 5%;
  margin-right: 5%;
  font-size: 1.5em;
}